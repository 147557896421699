<template>
  <div class="page">
    <div class="search-box section">
      <el-form ref="query" :inline="true" :model="query" size="medium">
        <el-form-item prop="region">
          <el-cascader v-model="defaultRegion" :clearable="true" :filterable="true" :props="props"
                       :show-all-levels="false" placeholder="请选择区域"/>
        </el-form-item>
        <el-form-item prop="bindStatus">
          <el-select
              v-model="query.bindStatus"
              placeholder="请选择绑定状态"
              style="width: 260px"
          >
            <el-option label="已绑定" value="0"></el-option>
            <el-option label="未绑定" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="authorityStatus">
          <el-select
              v-model="query.authorityStatus"
              placeholder="请选择认证状态"
              style="width: 260px"
          >
            <el-option label="未通过" value="0"></el-option>
            <el-option label="未认证" value="1"></el-option>
            <el-option label="待签约" value="2"></el-option>
            <el-option label="已认证" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="userKeyword">
          <el-input
              v-model="query.userKeyword"
              placeholder="用户手机号码/用户编号"
              style="width: 260px"
          />
        </el-form-item>
        <el-form-item prop="plateNumber">
          <NumplateSuggest
              v-model="query.plateNumber"
              placeholder="车牌号码（最小4个字符）"
              style="width: 260px"
          />
          <!-- <el-input
            placeholder="车牌号码"
            v-model="query.plateNumber"
            style="width: 260px"
          /> -->
        </el-form-item>
        <el-form-item prop="registDate">
          <el-date-picker
              v-model="query.registDate"
              end-placeholder="结束日期"
              range-separator="至"
              start-placeholder="开始日期"
              type="daterange"
              value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="authenticationTime">
          <div class="xxd-input__wrap">
            <span>认证时间</span>
            <el-date-picker
                v-model="query.authenticationTime"
                align="right"
                end-placeholder="结束时间"
                range-separator="至"
                start-placeholder="开始时间"
                type="daterange"
                value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">搜索</el-button>
          <el-button @click="doReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box section">
      <div class="op-box">
        <div class="op-content">
          共计<span class="total-size">{{ pagination.totalSize }}</span
        >条信息
        </div>
        <div class="op-extra">
          <icon-button
              v-if="hasRights('001003001003')"
              icon="share"
              title="导出"
              @click.native="exportData"
          />
        </div>
      </div>
      <el-table
          v-loading="isLoading"
          :data="dataList"
          border
          class="common-table"
          size="medium"
          style="width: 100%"
      >
        <el-table-column label="手机号码" prop="userPhone" width="160px"/>
        <el-table-column label="用户编号" prop="userCode" width="200px"/>
        <el-table-column label="注册地址" prop="registRegion" width="160px"/>
        <el-table-column label="昵称" prop="nickName" width="160px"/>
        <el-table-column label="用户姓名" prop="userName" width="160px"/>
        <el-table-column label="注册来源" prop="registSource" width="160px"/>
        <el-table-column label="注册时间" prop="registTime" width="240px">
          <template slot-scope="scope">
            <span>{{ dateFormat(new Date(scope.row.registTime)) }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="最后登录时间"
            prop="theLastLoginTime"
            width="240px"
        />
        <el-table-column
            label="绑定车辆数"
            prop="bindVehicleCount"
            width="100px"
        />
        <el-table-column
            label="认证状态"
            prop="authorityStatus"
            width="100px"
        />
        <el-table-column
            label="认证时间"
            prop="authenticationTime"
            width="240px"
        />
        <el-table-column
            align="center"
            fixed="right"
            label="操作"
            width="200px"
        >
          <template slot-scope="scope">
            <el-button
                v-if="hasRights('001003001001')"
                size="small common-text"
                type="text"
                @click="toDetail(scope.row)"
            >详情
            </el-button
            >
            <el-button
                v-if="hasRights('001003001002')"
                size="small common-text"
                type="text"
                @click="toUserBills(scope.row)"
            >用户卡流
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
            :current-page="pagination.pageNo"
            :page-size="pagination.pageSize"
            :total="pagination.totalSize"
            background
            layout="sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 导出弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="exportDialogFormVisible"
        class="common-dialog"
        title="导出选项"
        width="600px"
    >
      <el-form ref="form" :model="form" class="label-weight">
        <el-form-item label="导出条数" label-width="120px" prop="exportSize">
          <el-col :span="16">
            <el-input
                v-model="form.exportSize"
                placeholder="请填写导出条数"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BaseMixin from "@/mixins/base";
import Permission from "@/mixins/permission";
import NumplateSuggest from "@/components/NumplateSuggest";
import {getRegionList, getUserList} from "@/api/user";
import {isEmpty} from "@/utils/utils";
import {doExport} from "@/api/common";

// let id = 0;

export default {
  name: "user_manage",
  mixins: [BaseMixin, Permission],
  components: {
    NumplateSuggest,
  },
  data() {
    return {
      defaultRegion: [],
      props: {
        lazy: true,
        async lazyLoad(node, resolve) {
          const {level, value} = node;
          console.log('node.value:', value)
          let regionId = level === 0 ? 0 : value;
          getRegionList({regionId: regionId})
              .then(({returnObject}) => {
                console.log('getRegionList:', returnObject)
                const nodes = returnObject.map(item => ({
                      value: item.id,
                      label: item.name,
                      leaf: item.level == 3,
                    })
                )
                resolve(nodes)
              })
        }
      },
      query: {
        region: '',
        bindStatus: "", // 0=>未绑定 1=>已绑定
        authorityStatus: [], // 0=>未通过 1=>未认证 2=>待签约 3=>已认证
        userKeyword: "", // 用户手机号码或用户编号
        plateNumber: "",
        registDate: [],
        registStartDate: "",
        registEndDate: "",
        authenticationTime: [],
        authenticationStartTime: "",
        authenticationEndTime: "",
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      },
      dataList: [],
      exportDialogFormVisible: false, //导出弹窗
      form: {
        exportSize: 1,
      },
    };
  },
  methods: {
    doReset() {
      this.$refs.query.resetFields();
      this.doSearch();
    },
    async search() {
      this.query.region = this.defaultRegion.length == 3 ? this.defaultRegion[2] : this.defaultRegion[1]
      const params = this.paramFormat(this.query);
      if (!isEmpty(params.registDate)) {
        params.registStartDate = params.registDate[0] + " 00:00:00";
        params.registEndDate = params.registDate[1] + " 23:59:59";
      }

      if (!isEmpty(params.authenticationTime)) {
        params.authenticationStartTime =
            params.authenticationTime[0] + " 00:00:00";
        params.authenticationEndTime =
            params.authenticationTime[1] + " 23:59:59";
      }

      this.isLoading = true;
      const res = await getUserList(params);
      this.isLoading = false;

      if (res && res.code === 30 && res.result && res.returnObject) {
        this.dataList = res.returnObject.list;
        this.pagination.totalSize = res.returnObject.total;
        this.pagination.totalPages = res.returnObject.pages;
        this.form.exportSize = res.returnObject.total;
      } else {
        this.dataList = [];
        this.pagination.totalSize = 0;
        this.pagination.totalPages = 0;
        this.form.exportSize = 0;
      }
    },
    async doSearch() {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      this.search();
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
      });
      const params = this.paramFormat(this.query);
      if (this.query.registDate.length > 0) {
        params["registStartDate"] = this.query.registDate[0] + " 00:00:00";
        params["registEndDate"] = this.query.registDate[1] + " 23:59:59";
      }
      if (this.query.authenticationTime.length > 0) {
        params["authenticationStartTime"] =
            this.query.authenticationTime[0] + " 00:00:00";
        params["authenticationEndTime"] =
            this.query.authenticationTime[1] + " 23:59:59";
      }
      params["isExport"] = "1";
      params["exportSize"] = this.form.exportSize;
      // window.open("http://172.16.69.129:81" + "/userManage/getUserManagementList?" + objectToQueryString(params));
      doExport("/userManage/getUserManagementList", params, "用户管理.xlsx");
      loading.close();
      this.exportDialogFormVisible = false;
    },
    toUserBills(row) {
      this.$router.push({
        path: "/user_manage/user_manage/bills",
        query: {
          id: row.id,
        },
      });
    },
    toDetail(row) {
      this.$router.push({
        path: "/user_manage/user_manage/detail",
        query: {
          id: row.id,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>
